.quiz-questions {
  .inner-wrapper {
    height: 100%;
  }

  .btn-question {
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 10px;
    transition: 1s;

    &.image-answer {
      border: 0;
      outline: none;

      &:hover {
        > div {
          opacity: 0.9;
        }
      }

      > div {
        background-color: $white;
        border: 2px solid $white;
        border-radius: 100%;
        height: 115px;
        overflow: hidden;
        width: 115px;
      }
    }

    &.text-answer {
      background-color: $white;
      border: none;
      border: 2px solid $white;
      border-radius: 20px;
      color: $black;
      display: flex;
      flex-direction: column !important;
      font-size: 0.9rem;
      min-height: 40px;
      margin: 0 auto;
      text-align: center;
      width: 425px;
      line-height: 1.2;
      padding: 9px 10px;

      &:active,
      &:focus {
        background-color: $creme;
      }
      &:hover {
        opacity: 0.9 !important;
      }
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
  }

  .note {
    border: 2px solid $white;
    border-radius: 20px;
    color: $white;
    max-width: 450px;
    padding: 10px;
  }
}
