.step-progress-bar {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  position: relative;
  text-align: center;
  width: 140px;

  &::after {
    background: #85d0ce;
    bottom: 5.75px;
    content: " ";
    height: 0.7px;
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .stepBullet {
    background: #d9d9d9;
    border-radius: 100%;
    height: 12.65px;
    list-style: none;
    padding-left: 0;
    width: 12.65px;
    z-index: 2;

    &.done {
      background: #85d0ce;
    }

    &.active {
      background: #208380;
      box-shadow: 0px 0px 8px 3px rgba(32, 131, 128, 0.4);
    }
  }
}
