// Colors

$creme: #e1b693;
$green: #5ccace;
$white: #fff;
$black: #000;
$gray: rgb(136, 134, 134);

$primary: $white;
$secondary: $black;
$headings-color: $white;

$font-family-sans-serif: "Helvetica Neue LT";
$headings-font-family: "Helvetica Neue LT";

$utilities: (
  "custom-margin-top": (
    property: margin-top,
    class: mt,
    values: (
      0: 0,
      1: 0.4rem,
      2: 0.8rem,
      3: 1.2rem,
      4: 1.6rem,
      5: 2rem,
      6: 4rem,
    ),
  ),
  "opacity": (
    property: opacity,
    values: (
      0: 0,
      25: 0.25,
      50: 0.5,
      75: 0.75,
      90: 0.9,
      100: 1,
    ),
  ),
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 4,
  7: $spacer * 9,
);

$theme-colors: (
  "primary": $white,
  "secondary": $black,
  "creme": $creme,
  "green": $green,
  "white": $white,
);

$body-color: white;
$component-active-color: $white;
$component-active-bg: $primary;

$font-size-base: 0.8rem;
$headings-font-weight: 700;
$headings-line-height: 1;
$h1-font-size: 3rem;
$h2-font-size: 2.5rem;
$h3-font-size: 2rem;

$btn-focus-box-shadow: 0;
$btn-font-family: $font-family-sans-serif;
$btn-font-size: 1.5rem;
$btn-padding-x: 3rem;
$btn-padding-y: 0.3rem;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
$btn-border-radius: 40px;
$btn-border-radius-sm: 30px;
$btn-border-radius-lg: 40px;

$border-radius: 10px;
$border-radius-sm: 7px;
$tooltip-max-width: 290px;
$tooltip-bg: $white;
$tooltip-color: $white;
$tooltip-padding-y: 20px;
$tooltip-padding-x: 20px;
$tooltip-opacity: 1;

$input-bg: $white;
$input-btn-font-size-sm: 0.8rem;
$input-border-color: $white;
$input-border-width: 3px;
$input-focus-border-color: $white;
$input-color: $black;
$input-focus-box-shadow: none;
$input-padding-x: 1.3rem;
$input-padding-y: 0.5rem;
$form-check-input-width: 1.5em;
$form-check-input-border: 3px solid $white;
$form-check-input-border-radius: 50%;

$enable-negative-margins: true;

@import "~bootstrap/scss/bootstrap";
