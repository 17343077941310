// THEMES SPECIFIC
.wrapper {
  transition: background-image 0.2s ease-in-out;;
  background-image: url(/assets/images/bg.jpg);

  @include media-breakpoint-down(sm) {
    background-image: url(/assets/images/bg-mobile.jpg);
  }
  &.moska-nega {
    background-image: url(/assets/images/bg-quiz-male.jpg) !important;
    .btn-question {
      &.image-answer {
        > div {
          border-color: $green;
        }

        &:active,
        &:focus {
          > div {
            background-color: $green;
          }
        }
      }

      &.text-answer {
        border-color: $green;
        &:active,
        &:focus {
          background-color: $green;
        }
      }
    }

    .note {
      background-color: $green;
      border-color: $green;
      color: $black;
    }
    .step-progress-bar {
      &::after {
        background: #545454;
      }
      .stepBullet {
        background: #545454;
      }
      .stepBullet.done {
        background: $green;
      }
      .stepBullet.active {
        background: $green;
        box-shadow: 0px 0px 15px 3px $green;
      }
    }
  }

  &.zenska-nega {
    background-image: url(/assets/images/bg-quiz-female.jpg) !important;
    .btn-question {
      &.image-answer {
        > div {
          border-color: $creme;
        }

        &:active,
        &:focus {
          > div {
            background-color: $creme;
          }
        }
      }

      &.text-answer {
        border-color: $creme;
        &:active,
        &:focus {
          background-color: $creme;
        }
      }
    }
    .note {
      background-color: $creme;
      border-color: $creme;
      color: $black;
    }
    .step-progress-bar {
      &::after {
        background: #545454;
      }
      .stepBullet {
        background: #545454;
      }
      .stepBullet.done {
        background: $creme;
      }
      .stepBullet.active {
        background: $creme;
        box-shadow: 0px 0px 15px 3px $creme;
      }
    }
  }
}
