@import "../fonts/fontsBraun.css";
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

body {
  box-sizing: border-box;
  color: $white;
  font-family: "Helvetica Neue LT";
  margin: 0;
  overflow-x: hidden;
  padding: 0;
}

.quiz {
  height: 100vh;
  margin: 0;
  max-width: 100vw;
  padding: 0;

  .wrapper {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .inner-wrapper {
      background-color: $black;
      width: 800px;
      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }
}

// Common

.logo {
  position: absolute;
  right: 20px;
  top: 20px;
  @include media-breakpoint-down(sm) {
    right: 10px;
    top: 10px;
    width: 45px;
  }
}

.lead {
  font-weight: 400;
  @include media-breakpoint-down(sm) {
    font-size: 0.9rem;
  }
}

.btn:focus {
  box-shadow: none;
  outline: none;
}

.btn-back {
  border: none;
  bottom: 36px;
  color: $gray;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  left: 0;
  margin-left: auto !important;
  margin-right: auto !important;
  opacity: 0.85;
  outline: none;
  position: absolute;
  right: 0;
  text-align: center;

  .bi-arrow-left {
    font-size: 20px;
    position: relative;
    top: 2px;
  }
}

/*********** 
For Dev 
***********/

// Show filtered products

.dev-filtered-products {
  background-color: rgb(4, 224, 177);
  bottom: 0;
  display: none;
  left: 0;
  max-height: 200px;
  overflow: scroll;

  padding: 5px;
  position: fixed;
  width: 100%;

  span {
    color: #000;
    display: inline-block;
    font-size: 12px;
    padding: 5px 10px;
  }
}

.image-question-answer-wrapper {
  display: inline-flex;
  justify-content: space-around;
  flex-flow: column;
  @include media-breakpoint-up(md) {
    flex-flow: row;
  }
}

.image-answer-wrapper {
  flex: 1 1;
}

.footer-logo {
  //opacity: .35;
  position: fixed;
  bottom: 20px;
  right: 10px;
  left: 10px;
  text-align: center;

  img{
    width: 60px;
  }

  @include media-breakpoint-down(sm) {
    //width: 80px;
  }

  &:hover {
    //opacity: .5;
  }
}
